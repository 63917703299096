<template>
  <v-card>
    <v-card-title
      class="pb-1"
    >
      {{ $vuetify.lang.t('$vuetify.temperature.dashboardDataMap') }}
      <v-spacer></v-spacer>
      <v-btn-toggle
        v-model="statisticsType"
        color="white"
        class="mr-1"
        dense
        mandatory
        light
      >
        <v-btn
          active-class="deep-purple"
        >
          {{ $vuetify.lang.t('$vuetify.temperature.dashboardTotalRecords') }}
        </v-btn>

        <v-btn
          active-class="warning"
        >
          {{ $vuetify.lang.t('$vuetify.temperature.dashboardWarnRecords') }}
        </v-btn>

        <v-btn
          active-class="blue"
        >
          {{ $vuetify.lang.t('$vuetify.temperature.dashboardTotalPersons') }}
        </v-btn>

        <v-btn
          active-class="red"
        >
          {{ $vuetify.lang.t('$vuetify.temperature.dashboardWarnPersons') }}
        </v-btn>


      </v-btn-toggle>
    </v-card-title>
    <v-responsive :aspect-ratio="2/2">
      <template
        v-if="options"
      >
        <v-chart
          :options="options"
        />
      </template>
    </v-responsive>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';

  import 'echarts/map/js/china.js';
  import 'echarts/map/js/province/anhui.js';

  export default{
    name: 'StatisticsCardMap',

    components: {
    },

    props: {
      monitorDatas: {
        type: Array,
        required: true,
        default: () => []
      },
      regionLevel: {
        type: String,
        required: true,
        default: () => null
      },
      regionId: {
        type: String,
        required: true,
        default: () => null
      }
    },

    data() {
      return {
        statisticsType: 0,
        totalRecordData: [],
        warnRecordData: [],
        totalPersonData: [],
        warnPersonData: [],
        totalRecordColors: [
          '#311B92',
          '#4527A0',
          '#512DA8',
          '#5E35B1',
          '#7E57C2',
          '#9575CD',
          '#B39DDB',
          '#D1C4E9',
          '#EDE7F6'
        ],
        warnRecordColors: [
          "#E65100",
          "#EF6C00",
          "#F57C00",
           "#FB8C00",
           "#FFA726",
           "#FFB74D",
           "#FFCC80",
           "#FFE0B2",
           "#FFF3E0"
        ],
        totalPersonColors: [
          '#0D47A1',
          '#1565C0',
          '#1976D2',
          '#1E88E5',
          '#42A5F5',
          '#64B5F6',
          '#90CAF9',
          '#BBDEFB',
          '#E3F2FD'
        ],
        warnPersonColors: [
          '#B71C1C',
          '#C62828',
          '#D32F2F',
          '#E53935',
          '#EF5350',
          '#E57373',
          '#EF9A9A',
          '#FFCDD2',
          '#FFEBEE'
        ],
        defaultOption: {
          grid: {
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px'
          },
          tooltip : {
              trigger: 'item'
          },
          visualMap: {
              x: 'left',
              y: 'bottom',
              text:['高','低'],
              color: null,
              calculable : true
          },
          roamController: {
              show: true,
              x: 'right',
              mapTypeControl: {
                  'china': true
              }
          },
          series : [
              {
                  name: '',
                  type: 'map',
                  mapType: 'china',
                  roam: false,
                  itemStyle:{
                      normal:{label:{show:true}},
                      emphasis:{label:{show:true}}
                  },
                  data:[]
              }
          ]
        },
        options: null
      }
    },

    watch: {
      statisticsType: {
         handler: function() {
            this.generateOption()
         }
      },

      monitorDatas: {
         handler: function() {
            this.initializeDatas()
         }
      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },

    methods: {
      initializeDatas () {
        this.totalRecordData = []
        this.warnRecordData = []
        this.totalPersonData = []
        this.warnPersonData = []
        if (this.monitorDatas.length > 0) {
          this.monitorDatas.forEach( element => {
            this.totalRecordData.push({ name: element.regionName, value: element.totalRecordNum })
            this.warnRecordData.push({ name: element.regionName, value: element.warnRecordNum })
            this.totalPersonData.push({ name: element.regionName, value: element.totalPersonNum })
            this.warnPersonData.push({ name: element.regionName, value: element.warnPersonNum })
          })
        }
        this.generateOption()
      },

      generateOption() {
        let map = null
        if (this.regionId) {
          if (this.regionLevel === 'CITY') {
            map = this.regionId.substr(0, 4) + '00'
          } else {
            map = this.regionId.substr(0, 6)
          }
        }


        let optionTmp = Object.assign({}, this.defaultOption)
        if (map) {
          optionTmp.series[0].mapType = map
        }
        if (this.statisticsType === 1) {
          optionTmp.series[0].data = this.warnRecordData
          optionTmp.series[0].name = this.$vuetify.lang.t('$vuetify.temperature.dashboardWarnRecords')
          optionTmp.visualMap.color = this.warnRecordColors
        } else if (this.statisticsType === 2) {
          optionTmp.series[0].data = this.totalPersonData
          optionTmp.series[0].name = this.$vuetify.lang.t('$vuetify.temperature.dashboardTotalPersons')
          optionTmp.visualMap.color = this.totalPersonColors
        } else if (this.statisticsType === 3) {
          optionTmp.series[0].data = this.warnPersonData
          optionTmp.series[0].name = this.$vuetify.lang.t('$vuetify.temperature.dashboardWarnPersons')
          optionTmp.visualMap.color = this.warnPersonColors
        } else {
          optionTmp.series[0].data = this.totalRecordData
          optionTmp.series[0].name = this.$vuetify.lang.t('$vuetify.temperature.dashboardTotalRecords')
          optionTmp.visualMap.color = this.totalRecordColors
        }
        this.options = optionTmp
      }

    },

    created() {
      this.initializeDatas()
    }
  }
</script>

<style lang="css" scoped>
  .echarts {
    width: 100%;
    height: 100%;
  }

  .v-btn.v-btn.v-btn--active .v-icon {
    color: #fff !important;
  }
</style>
